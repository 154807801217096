<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="add()"></v-button>
      </template>
      <template #searchSlot>
        <v-select
          @clear="servingCell_clear_searchParam"
          @change="searchCommunityChange"
          clearable
          :options="communityList"
          v-model="servingBuilding"
          label="服务小区"
        />
        <v-select
          @change="buildChange_searchParam"
          clearable
          :multiple="true"
          :options="searchBuildList"
          v-model="servingBuilding_searchParam"
          label="服务楼幢"
        />
        <v-input
          label="姓名"
          v-model="searchParam.name"
          placeholder="请输入姓名"
        />
        <v-input
          label="手机号"
          v-model="searchParam.phone"
          placeholder="请输入手机号"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          type="text"
          @click="edit(scope.row)"
        />
        <v-button text="删除" type="text" @click="remove(scope.row)" />
      </template>
    </v-list>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
      width="30%"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="姓名：">
          <v-input
            placeholder="请输入姓名"
            clearable
            :width="width"
            v-model="form.name"
          ></v-input>
        </el-form-item>
        <el-form-item label="手机号：">
          <div class="form-item">
            <v-input
              placeholder="请输入手机号"
              clearable
              :disabled="isEdit"
              :width="width"
              v-model="form.phone"
              @blur="handelInputBlur"
            ></v-input>
            <v-button
              text="编辑"
              @click="handelClick"
              v-if="dialogTitle == '编辑'"
              style="margin-left: 10px"
            ></v-button>
          </div>
        </el-form-item>
        <el-form-item label="区域：">
          <v-cascader
            placeholder="默认表示全部"
            :width="width"
            v-model="servingCell"
            :options="placeTreeList"
            :props="props"
          />
        </el-form-item>
        <el-form-item label="工单类型：">
          <v-cascader
            placeholder="默认表示全部"
            :width="width"
            v-model="servingIncidentType"
            :options="workOrderTreeList"
            :props="props"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer dialogBottomBtn">
        <v-button size="medium" @click="backDialog()" text="返回"></v-button>
        <v-button size="medium" @click="submit()" text="保存"></v-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  servingBuildingListUrl,
  showReceiverListUrl,
  showServingBuildingListUrl,
  addReceiverUrl,
  receiverCompileUrl,
  removeCompileUrl,
  communityListUrl,
  getIncidentTypeTreeUrl,
  getBuildListURL,
  receiverPhoneEdit,
} from "./api.js";
import { Message, MessageBox } from "element-ui";
import { stringify } from "querystring";
import { setAESCbcEncrypt } from "@/utils/common";
export default {
  name: "staffing",
  data() {
    return {
      width: 250,
      tableUrl: showReceiverListUrl,
      dialogTitle: "",
      dialogFormVisible: false,
      servingBuilding: null,
      searchParam: {
        servingCell: [],
        name: null,
        phone: null,
      },
      servingBuilding_searchParam: [],
      headers: [
        {
          prop: "name",
          label: "姓名",
        },
        {
          prop: "phone",
          label: "手机号",
        },
        {
          prop: "servingCellName",
          label: "服务小区/楼栋",
          formatter: (row, prop) => {
            return row.servingCellName || "-";
          },
        },
        {
          prop: "servingIncidentTypeName",
          label: "工单/事件类型",
          formatter: (row, prop) => {
            return row.servingIncidentTypeName || "-";
          },
        },
        // {
        // prop: "servingBuilding",
        // label: "服务楼栋",
        // formatter: (row, prop) => {
        //   let list = row.servingBuilding.split(",");
        //   let list2 = [];
        //   list.forEach((item) => {
        //     item = item + "幢";
        //     list2.push(item);
        //   });
        //   return row.servingBuilding == "" ? "-" : list2.join(",");
        // },
        // },
      ],
      form: {
        id: null,
        name: null,
        phone: null,
        servingCell: null,
        servingIncidentType: null,
      },
      servingCell: [],
      servingIncidentType: [],

      servingBuilding_temp: [],
      communityList: [], //小区
      buildList: [], //楼幢
      searchBuildList: [], //可添加的楼幢
      workOrderTreeList: [], //工单类型级联数据
      placeTreeList: [], //区域级联数据
      spacePathIds: [],
      workTypeIds: [],
      props: {
        multiple: true,
        label: "name",
        value: "id",
        children: "children",
      },
      isEdit: false,
    };
  },
  watch: {
    servingIncidentType: {
      handler(val) {
        this.form.servingIncidentType = val
          .map((a) => a[a.length - 1])
          .join(",");
      },
      deep: true,
    },
    servingCell: {
      handler(val) {
        this.form.servingCell = val.map((a) => a[a.length - 1]).join(",");
      },
      deep: true,
    },
  },
  async mounted() {
    this.getCommunityList();
    this.getBuildList();
    await this.getWorkOrderTreeList();
  },
  methods: {
    //编辑手机号
    handelInputBlur() {
      if (this.dialogTitle == "编辑") {
        const params = {
          id: this.form.id,
          phone: setAESCbcEncrypt(this.form.phone),
        };
        this.$axios.post(receiverPhoneEdit, params).then((res) => {
          if (res.code == 200) {
            this.isEdit = true;
            this.$message.success(res.msg);
          }
        });
      }
    },
    handelClick() {
      this.form.phone = "";
      this.isEdit = false;
    },
    // 获取小区楼栋级联选择数据
    async getBuildList() {
      let res = await this.$axios.get(
        `${getBuildListURL}?specifyCollectionTypes=4,5`
      );
      if (res.code === 200) {
        let opt = this.dealDataAddParentIds(res.data);
        this.placeTreeList = opt;
        console.log(this.placeTreeList);
      }
    },
    dealDataAddParentIds(data, ids = []) {
      data.forEach((v) => {
        if (v.children) {
          this.dealDataAddParentIds(v.children, [...ids, v.id]);
        } else {
          this.spacePathIds.push(v);
        }
        v.treeIds = [...ids, v.id];
      });
      return data;
    },
    // 获取工单类型级联选择数据
    async getWorkOrderTreeList() {
      let params = {
        tenantId: this.$store.state.app.userInfo.tenantId,
      };
      let res = await this.$axios.get(`${getIncidentTypeTreeUrl}`, {
        params: params,
      });
      if (res.code == 200) {
        res.data.forEach((item) => {
          item.id = item.id + "";
          item.incidentTypeList.forEach((item1) => {
            item1.name = item1.incidentName;
            item1.id = item1.id + "";
          });
          item.children = item.incidentTypeList;
        });
        let opt = this.dealDataAddParentIds1(res.data);
        this.workOrderTreeList = opt;
        console.log(this.workOrderTreeList);
      }
    },
    dealDataAddParentIds1(data, ids = []) {
      data.forEach((v) => {
        if (v.children) {
          this.dealDataAddParentIds1(v.children, [...ids, v.id]);
        } else {
          this.workTypeIds.push(v);
        }
        v.treeIds = [...ids, v.id];
      });
      return data;
    },
    submit() {
      //保存
      if (!this.form.name || this.form.name == "") {
        this.$message.error("请输入姓名！");
        return;
      }
      if (!this.form.phone || this.form.phone == "") {
        this.$message.error("请输入手机号！");
        return;
      }
      // if (this.form.phone.length != 11) {
      //   this.$message.error("请输入正确的手机号！");
      //   return;
      // }
      if (this.servingCell.length == 0) {
        this.$message.error("请选择服务小区/楼栋！");
        return;
      }
      if (this.servingIncidentType.length == 0) {
        this.$message.error("请选择工单/事件类型！");
        return;
      }
      if (this.dialogTitle == "新增") {
        let params = {
          name: this.form.name,
          phone: setAESCbcEncrypt(this.form.phone),
          servingCell: this.form.servingCell,
          servingIncidentType: this.form.servingIncidentType,
        };
        this.$axios.post(`${addReceiverUrl}`, params).then((res) => {
          if (res.code === 200) {
            this.dialogFormVisible = false;
            Message.success(res.msg);
            this.$refs.list.search();
          }
        });
      } else if (this.dialogTitle == "编辑") {
        let params = {
          id: this.form.id,
          name: this.form.name,
          phone: this.form.phone,
          servingCell: this.form.servingCell,
          servingIncidentType: this.form.servingIncidentType,
        };
        this.$axios.post(`${receiverCompileUrl}`, params).then((res) => {
          if (res.code === 200) {
            this.dialogFormVisible = false;
            Message.success(res.msg);
            this.$refs.list.search();
          }
        });
      }
    },
    backDialog() {
      this.dialogFormVisible = false;
    },
    add() {
      //新增
      this.isEdit = false;
      this.dialogFormVisible = true;
      this.dialogTitle = "新增";
      this.form.name = null;
      this.form.phone = null;
      this.form.servingCell = null;
      this.servingCell = [];
      this.form.servingIncidentType = null;
      this.servingIncidentType = [];
    },
    edit(data) {
      this.isEdit = true;
      //编辑
      this.dialogFormVisible = true;
      this.dialogTitle = "编辑";
      this.servingCell = [];
      this.servingIncidentType = [];
      console.log(data);
      Object.keys(this.form).forEach((key) => {
        // if (key === "servingCell" && !data[key]) {
        //   this.form[key] = "";
        //   return;
        // }
        // if (key === "servingIncidentType" && !data[key]) {
        //   this.form[key] = "";
        //   return;
        // }
        this.form[key] = data[key];
      });
      const ids = this.form.servingCell ? this.form.servingCell.split(",") : [];
      this.spacePathIds.forEach((item) => {
        if (ids.includes(item.id)) {
          this.servingCell.push([...item.treeIds]);
        }
      });
      const ids1 = this.form.servingIncidentType
        ? this.form.servingIncidentType.split(",")
        : [];
      this.workTypeIds.forEach((item) => {
        if (ids1.includes(item.id)) {
          this.servingIncidentType.push([...item.treeIds]);
        }
      });
    },
    remove(data) {
      //删除
      MessageBox.confirm("是否删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: data.id,
          };
          this.$axios
            .post(`${removeCompileUrl}`, this.$qs.stringify(params))
            .then((res) => {
              if (res.code === 200) {
                Message.success("操作成功！");
                this.$refs.list.search();
              }
            });
        })
        .catch(() => {});
    },
    searchCommunityChange(value) {
      //筛选条件小区选择change（获取楼幢）
      this.searchBuildList = [];
      this.servingBuilding_searchParam = [];
      let params = {
        spaceId: value,
      };
      this.$axios
        .get(`${servingBuildingListUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            res.data.forEach((item) => {
              item.value = item.buildingId;
              item.label = item.buildingName;
            });
            this.searchBuildList = res.data;
          }
        });
    },

    buildChange_searchParam(value) {
      //筛选条件楼幢选择change
      this.searchParam.servingCell = value.join(",");
    },
    servingCell_clear_searchParam() {
      this.servingBuilding_searchParam = null;
    },
    getCommunityList() {
      //获取小区
      let params = {
        specifyCollectionTypes: "4,5",
      };
      this.$axios
        .get(`${communityListUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            res.data.forEach((item) => {
              item.value = item.id;
              item.label = item.name;
            });
            this.communityList = res.data;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  height: 100%;
  .form-item {
    display: flex;
  }
}
</style>
